import '../assets/styles/thingsboard.css';
import logoSmartRuralPeixeBranco from '../assets/images/smartrural_logo_named_white.png';
import logoOasis from '../assets/images/Oasis.png';
import qrcode from '../assets/images/QRcodeContato.svg';

const HorizontalCard = () => {
    return(
        <div className="horizontal-card">
        <img src={logoSmartRuralPeixeBranco} alt="logo peixe smartrural" className="card-image2 logosmart"/>
        <img src={logoOasis} alt="logo oasis" className="card-image oasis"/>
        <img src={qrcode} alt="qr code" className="card-image qrcode"/>
    </div>
    )
}

export default HorizontalCard;