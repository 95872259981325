import '../assets/styles/thingsboard.css';
import logoGalinhaBranco from '../assets/images/SmartRuralLogoGalinhaBranco.svg';
import qrcode from '../assets/images/QRcodeContato.svg';
import logoOvonovo from '../assets/images/Logo_OvoNovo1.png';


const HorizontalCard = () => {
    return (
        <div className="horizontal-card">
            <img src={logoGalinhaBranco} alt="logo galinha" className="card-image logosmart"/>
            <img src={logoOvonovo} alt="logo ovonovo" className="card-image ovonovo"/>
            <img src={qrcode} alt="qr code" className="card-image qrcode"/>
        </div>
    );
};

export default HorizontalCard;
