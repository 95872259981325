import React, { useState, useEffect } from 'react';
import firebaseService from '../firebase/firebase';
import '../assets/styles/aviaryDetailsPage.css';
import axios from 'axios';
import { getAuth } from "firebase/auth";


const AviaryDetailsPage = ({ aviaryId }) => {
    const [temperatureMax, setTemperatureMax] = useState("");
    const [temperatureMin, setTemperatureMin] = useState("");
    const [humidityMax, setHumidityMax] = useState("");
    const [humidityMin, setHumidityMin] = useState("");
    const [linhagem, setLinhagem] = useState("");
    const [unitName, setUnitName] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [installationDate, setInstallationDate] = useState("");


    useEffect(() => {
        const path = window.location.pathname;
        const segments = path.split('/');
        const lastSegment = segments.pop() || segments.pop();

        const fetchData = async () => {
            try {
                const uid = getAuth().currentUser.uid;
                const data = await firebaseService.getDatabaseInfo();
                let idPropriedade_user = data.usuarios[uid].propriedadeId;
                const unitData = data.propriedades?.[idPropriedade_user]?.unidadesProdutivas?.[lastSegment];

                if (unitData) {

                    setUnitName(unitData.nome || `Aviário ${aviaryId}`);

                    // Fazer a requisição de login com axios para obter jwt
                    try {
                        const response = await axios.post('https://thingsboard.cloud/api/auth/login', {
                            username: data.thingsboardCredentials.username,
                            password: data.thingsboardCredentials.password
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'accept': 'application/json'
                            }
                        });
                        const token = response.data.token;
                        setAuthToken(token);

                        // Fazer a requisição para obter os atributos do ASSET
                        try {
                            const attributesResponse = await axios.get(`https://thingsboard.cloud/api/plugins/telemetry/ASSET/${lastSegment}/values/attributes/SERVER_SCOPE`, {
                                headers: {
                                    'accept': 'application/json',
                                    'X-Authorization': `Bearer ${token}`
                                }
                            });
                            const listaParametros = attributesResponse.data;

                            setTemperatureMax(listaParametros.find(element => element.key === 'temperature_max').value);
                            setTemperatureMin(listaParametros.find(element => element.key === 'temperature_min').value);
                            setHumidityMax(listaParametros.find(element => element.key === 'humidity_max').value);
                            setHumidityMin(listaParametros.find(element => element.key === 'humidity_min').value);
                            setLinhagem(listaParametros.find(element => element.key === 'lineage').value);
                            setLatitude(listaParametros.find(element => element.key === 'latitude').value)
                            setLongitude(listaParametros.find(element => element.key === 'longitude').value);
                            setBirthDate(listaParametros.find(element => element.key === 'birth_date').value);
                            setInstallationDate(listaParametros.find(element => element.key === 'installation_date').value);


                        } catch (attributesError) {
                            console.error("Erro de atributos", attributesError);
                        }

                    } catch (loginError) {
                        console.error("Erro no login", loginError.message);
                    }
                }
            } catch (error) {
                console.error("Erro ao obter informações", error);
            }
        };
        fetchData();
    }, [aviaryId]);

    const handleChange = (event) => {
        const { id, value } = event.target;
        switch (id) {
            case 'temperatureMax':
                setTemperatureMax(value);
                break;
            case 'temperatureMin':
                setTemperatureMin(value);
                break;
            case 'humidityMax':
                setHumidityMax(value);
                break;
            case 'humidityMin':
                setHumidityMin(value);
                break;
            case 'linhagem':
                setLinhagem(value);
                break;
            case 'latitude':
                setLatitude(value);
                break;
            case 'longitude':
                setLongitude(value);
                break;
            case 'birthDate':
                setBirthDate(convertHtmlToDate(value));
                break;
            case 'installationDate':
                setInstallationDate(convertHtmlToDate(value));
                break;


            default:
                console.warn(`Input não reconhecido: ${id}`);
                break;
        }
    };

    const handleButtonClick = async () => {
        try {
            const response = await axios.post(`https://thingsboard.cloud/api/plugins/telemetry/ASSET/${aviaryId}/attributes/SERVER_SCOPE`, {
                latitude: latitude,
                longitude: longitude,
                birth_date: birthDate,
                installation_date: installationDate,
                lineage: linhagem,
                temperature_max: temperatureMax,
                temperature_min: temperatureMin,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'X-Authorization': `Bearer ${authToken}`
                }
            });
            console.log("Dados atualizados com sucesso:", response.data);
            alert('Dados enviados!');
        } catch (error) {
            console.error("Erro ao atualizar dados:", error);
            alert('erro');

        }
    };


    function convertDateToHtml(dateString) {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    }

    function convertHtmlToDate(Date) {
        const [year, month, day] = Date.split('-');
        return `${day}/${month}/${year}`;
    }



    return (
        <div className='full-page-background'>
            <div className='container3'>
                <div className='div-nome'>
                <div className='container-dos-inputs text-bg-secondary'>
                    <h2 className='titulo'>{unitName}</h2>

                    <div className='conjunto-dois-elementos'>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Temperatura Máxima</span>
                        <input
                            id='temperatureMax'
                            type="text"
                            className="form-control"
                            placeholder={temperatureMax}
                            onChange={handleChange}
                        />
                        <span className="input-group-text">°C</span>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Temperatura Mínima</span>
                        <input
                            id='temperatureMin'
                            type="text"
                            className="form-control"
                            placeholder={temperatureMin}
                            onChange={handleChange}
                        />
                        <span className="input-group-text">°C</span>
                    </div>
                    </div>

                    <div className='conjunto-dois-elementos'>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Humidade Máxima</span>
                        <input
                            id='humidityMax'
                            type="text"
                            className="form-control"
                            placeholder={humidityMax}
                            onChange={handleChange}
                        />
                        <span className="input-group-text">%</span>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Humidade Mínima</span>
                        <input
                            id='humidityMin'
                            type="text"
                            className="form-control"
                            placeholder={humidityMin}
                            onChange={handleChange}
                        />
                        <span className="input-group-text">%</span>
                        </div>
                    </div>

                    <div className='conjunto-um-elemento'>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Linhagem</span>
                        <input
                            id='linhagem'
                            type="text"
                            className="form-control"
                            placeholder={linhagem}
                            onChange={handleChange}
                        />
                        <span className="input-group-text"></span>
                    </div>
                    </div>

                    <div className='conjunto-dois-elementos'>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Latitude</span>
                        <input
                            id='latitude'
                            type="text"
                            className="form-control"
                            placeholder={latitude}
                            onChange={handleChange}
                        />
                        <span className="input-group-text">°</span>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Longitude</span>
                        <input
                            id='longitude'
                            type="text"
                            className="form-control"
                            placeholder={longitude}
                            onChange={handleChange}
                        />
                        <span className="input-group-text">°</span>
                        </div>
                    </div>

                    <div className='conjunto-dois-elementos'>

                    <div className="input-group mb-3">
                        <span className="input-group-text">Data de nascimento</span>
                        <input
                            id='birthDate'
                            type="date"
                            className="form-control"
                            value={convertDateToHtml(birthDate)}
                            placeholder={birthDate}
                            onChange={handleChange}
                        />
                        <span className="input-group-text"></span>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">Data de instalação</span>
                        <input
                            id='installationDate'
                            type="date"
                            className="form-control"
                            value={convertDateToHtml(installationDate)}
                            placeholder={installationDate}
                            onChange={handleChange}
                        />
                        <span className="input-group-text"></span>
                    </div>
                    </div>
                    </div> 
                </div>
            </div>
            <button type="button" className="btn btn-success btn-lg update-button2" onClick={handleButtonClick}>Enviar</button>
        </div>
    );
};

export default AviaryDetailsPage;
