import React, { useState, useEffect } from 'react';
import firebaseService from '../firebase/firebase';
import '../assets/styles/tankDetailsPage.css';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const Dropdown = ({ id, options, onChange, currentValue}) => {
    return (
        <div className="input-group mb-3">
            <span className="input-group-text">Selecione o cultivo</span>
            <select id={id} className="form-select" onChange={onChange} value={currentValue}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    );
};

const TankDetailsPage = ({ tankId }) => {
    const [temperatureMax, setTemperatureMax] = useState("");
    const [temperatureMin, setTemperatureMin] = useState("");
    const [phMax, setPhMax] = useState("");
    const [phMin, setPhMin] = useState("");
    const [oxygenMax, setOxygenConcentrationMax] = useState("");
    const [oxygenMin, setOxygenConcentrationMin] = useState("");
    const [saturationMax, setOxygenSaturationMax] = useState("");
    const [saturationMin, setOxygenSaturationMin] = useState("");
    const [linhagem, setLinhagem] = useState("");
    const [unitName, setUnitName] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [installationDate, setInstallationDate] = useState("");

    useEffect(() => {
        const path = window.location.pathname;
        const segments = path.split('/');
        const lastSegment = segments.pop() || segments.pop();

        const fetchData = async () => {
            try {
                const uid = getAuth().currentUser.uid;
                const data = await firebaseService.getDatabaseInfo();
                let idPropriedade_user = data.usuarios[uid].propriedadeId;
                const unitData = data.propriedades?.[idPropriedade_user]?.unidadesProdutivas?.[lastSegment];

                if (unitData) {
                    setUnitName(unitData.nome || `Tanque ${tankId}`);

                    try {
                        const response = await axios.post('https://thingsboard.cloud/api/auth/login', {
                            username: data.thingsboardCredentials.username,
                            password: data.thingsboardCredentials.password
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'accept': 'application/json'
                            }
                        });
                        const token = response.data.token;
                        setAuthToken(token);

                        try {
                            const attributesResponse = await axios.get(`https://thingsboard.cloud/api/plugins/telemetry/ASSET/${lastSegment}/values/attributes/SERVER_SCOPE`, {
                                headers: {
                                    'accept': 'application/json',
                                    'X-Authorization': `Bearer ${token}`
                                }
                            });
                            const listaParametros = attributesResponse.data;

                            setTemperatureMax(listaParametros.find(element => element.key === 'temperature_max').value);
                            setTemperatureMin(listaParametros.find(element => element.key === 'temperature_min').value);
                            setPhMax(listaParametros.find(element => element.key === 'ph_max').value);
                            setPhMin(listaParametros.find(element => element.key === 'ph_min').value);
                            setLinhagem(listaParametros.find(element => element.key === 'cultivo').value);
                            setLatitude(listaParametros.find(element => element.key === 'latitude').value);
                            setLongitude(listaParametros.find(element => element.key === 'longitude').value);
                            setBirthDate(listaParametros.find(element => element.key === 'birth_date').value);
                            setInstallationDate(listaParametros.find(element => element.key === 'installation_date').value);
                            setOxygenConcentrationMax(listaParametros.find(element => element.key === 'do_concentration_max').value);
                            setOxygenConcentrationMin(listaParametros.find(element => element.key === 'do_concentration_min').value);
                            setOxygenSaturationMax(listaParametros.find(element => element.key === 'do_saturation_max').value);
                            setOxygenSaturationMin(listaParametros.find(element => element.key === 'do_saturation_min').value);

                        } catch (attributesError) {
                            console.error("Erro de atributos", attributesError);
                        }

                    } catch (loginError) {
                        console.error("Erro no login", loginError.message);
                    }
                }
            } catch (error) {
                console.error("Erro ao obter informações", error);
            }
        };
        fetchData();
    }, [tankId]);

    const handleChange = (event) => {
        const { id, value } = event.target;
        switch (id) {
            case 'temperatureMax':
                setTemperatureMax(value);
                break;
            case 'temperatureMin':
                setTemperatureMin(value);
                break;
            case 'phMax':
                setPhMax(value);
                break;
            case 'phMin':
                setPhMin(value);
                break;
            case 'oxygenConcentrationMax':
                setOxygenConcentrationMax(value);
                break;
            case 'oxygenConcentrationMin':
                setOxygenConcentrationMin(value);
                break;
            case 'oxygenSaturationMax':
                setOxygenSaturationMax(value);
                break;
            case 'oxygenSaturationMin':
                setOxygenSaturationMin(value);
                break;
            case 'linhagem':
                setLinhagem(value);
                break;
            case 'latitude':
                setLatitude(value);
                break;
            case 'longitude':
                setLongitude(value);
                break;
            case 'birthDate':
                setBirthDate(convertHtmlToDate(value));
                break;
            case 'installationDate':
                setInstallationDate(convertHtmlToDate(value));
                break;
            default:
                console.warn(`Input não reconhecido: ${id}`);
                break;
        }
    };

    const handleButtonClick = async () => {
        try {
            const response = await axios.post(`https://thingsboard.cloud/api/plugins/telemetry/ASSET/${tankId}/attributes/SERVER_SCOPE`, {
                latitude: latitude,
                longitude: longitude,
                birth_date: birthDate,
                installation_date: installationDate,
                cultivo: linhagem,
                temperature_max: temperatureMax,
                temperature_min: temperatureMin,
                ph_max: phMax,
                ph_min: phMin,
                do_concentration_max: oxygenMax,
                do_concentration_min: oxygenMin,
                do_saturation_max: saturationMax,
                do_saturation_min: saturationMin
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'X-Authorization': `Bearer ${authToken}`
                }
            });
            console.log("Dados atualizados com sucesso:", response.data);
            alert('Dados enviados!');
        } catch (error) {
            console.error("Erro ao atualizar dados:", error);
            alert('Erro');
        }
    };

    function convertDateToHtml(dateString) {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    }

    function convertHtmlToDate(date) {
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    }

    return (
        <div className='full-page-background'>
            <div className='container3'>
                <div className='div-nome'>
                    <div className='container-dos-inputs text-bg-secondary'>
                        <h2 className='titulo'>{unitName}</h2>

                        <div className='conjunto-dois-elementos'>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Temperatura Máxima</span>
                                <input id='temperatureMax' type="text" className="form-control" placeholder={temperatureMax} onChange={handleChange} />
                                <span className="input-group-text">°C</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Temperatura Mínima</span>
                                <input id='temperatureMin' type="text" className="form-control" placeholder={temperatureMin} onChange={handleChange} />
                                <span className="input-group-text">°C</span>
                            </div>
                        </div>

                        <div className='conjunto-dois-elementos'>
                            <div className="input-group mb-3">
                                <span className="input-group-text">pH Máximo</span>
                                <input id='phMax' type="text" className="form-control" placeholder={phMax} onChange={handleChange} />
                                <span className="input-group-text"></span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">pH Mínimo</span>
                                <input id='phMin' type="text" className="form-control" placeholder={phMin} onChange={handleChange} />
                                <span className="input-group-text"></span>
                            </div>
                        </div>

                        <div className='conjunto-dois-elementos'>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Concentração máxima O₂</span>
                                <input id='oxygenConcentrationMax' type="text" className="form-control" placeholder={oxygenMax} onChange={handleChange} />
                                <span className="input-group-text">mg/L</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Concentração mínima O₂</span>
                                <input id='oxygenConcentrationMin' type="text" className="form-control" placeholder={oxygenMin} onChange={handleChange} />
                                <span className="input-group-text">mg/L</span>
                            </div>
                        </div>

                        <div className='conjunto-dois-elementos'>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Saturação máxima O₂</span>
                                <input id='oxygenSaturationMax' type="text" className="form-control" placeholder={saturationMax} onChange={handleChange} />
                                <span className="input-group-text">%</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Saturação mínima O₂</span>
                                <input id='oxygenSaturationMin' type="text" className="form-control" placeholder={saturationMin} onChange={handleChange} />
                                <span className="input-group-text">%</span>
                            </div>
                        </div>

                        <div className='conjunto-um-elemento'>
                            <Dropdown
                                id='linhagem'
                                options={[
                                    { value: 'fish', label: 'Peixe' },
                                    { value: 'shrimp', label: 'Camarão' }
                                ]}
                                onChange={handleChange}
                                currentValue={linhagem}
                            />
                        </div>

                        <div className='conjunto-dois-elementos'>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Latitude</span>
                                <input id='latitude' type="text" className="form-control" placeholder={latitude} onChange={handleChange} />
                                <span className="input-group-text">°</span>
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Longitude</span>
                                <input id='longitude' type="text" className="form-control" placeholder={longitude} onChange={handleChange} />
                                <span className="input-group-text">°</span>
                            </div>
                        </div>

                        <div className='conjunto-dois-elementos'>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Data de nascimento</span>
                                <input id='birthDate' type="date" className="form-control" value={convertDateToHtml(birthDate)} placeholder={birthDate} onChange={handleChange} />
                            </div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Data de instalação</span>
                                <input id='installationDate' type="date" className="form-control" value={convertDateToHtml(installationDate)} placeholder={installationDate} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-success btn-lg update-button2" onClick={handleButtonClick}>Enviar</button>
        </div>
    );
};

export default TankDetailsPage;