import "../assets/styles/loginpage.css";
import React, { useEffect, useState } from "react";
import logoNav from "../assets/images/smartrural_logo_named_white.png";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
//import { useNavigate } from "react-router-dom";
import firebaseService from '../firebase/firebase.js';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  
  const auth = getAuth();
  //const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const data = await firebaseService.getDatabaseInfo();
          console.log("Data:", data);
          const uid = getAuth().currentUser.uid;
          let idPropriedade_user = data.usuarios[uid].propriedadeId;
          if (data.propriedades[idPropriedade_user].nome === "ovonovo") {
            window.location.href = "https://thingsboard.cloud/dashboard/56913f40-1c22-11ef-813f-790f7ab3fcac?publicId=03e20080-fc20-11ee-ba48-77e255f86bfb";
          } else if (data.propriedades[idPropriedade_user].nome === "oasis") {
            window.location.href = "https://thingsboard.cloud/dashboard/04fdb060-06fa-11ef-bf1e-eb47e687e405?publicId=03e20080-fc20-11ee-ba48-77e255f86bfb";
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    });

    return () => unsubscribe();
  }, [auth]);


  async function handleSignIn(e) {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("User logged in:", user);
        //navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Login error:", error);
        setShowErrorModal(true);
        alert("Credenciais incorretas. Por favor, tente novamente.");
      });
  }

  function closeErrorModal() {
    setShowErrorModal(false);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      handleSignIn(e);
    }
  }

  return (
    <>
      <div className="elements">
        <img className="img-logo-login" src={logoNav} alt="logo" />
      </div>
      <div className="login-form">
        <div className="form-title">
          <h1>LOGIN</h1>
        </div>
        <div className="email">
          <div className="form-label">
            <label>EMAIL</label>
          </div>
          <div>
            <input
              type="email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <div className="password">
          <div className="form-label">
            <label>SENHA</label>
          </div>
          <div>
            <input
              type="password"
              className="form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary" onClick={handleSignIn}>
            ENTRAR
          </button>
        </div>
      </div>

      {showErrorModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Erro ao logar</h2>
            <button className="btn btn-red" onClick={closeErrorModal}>OK</button>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginPage;