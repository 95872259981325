import React, { useEffect, useState } from 'react';
import '../assets/styles/dashboardpage.css';
import firebaseService from '../firebase/firebase.js';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import chickenicon from '../assets/images/chickenicon.png';
import iconePeixe from '../assets/images/smartrural_fish_icon.png';
import iconeCamarao from '../assets/images/smartrural_shrimp_icon.png';
import HeaderOvo from '../components/HeaderOvonovo.jsx';
import HeaderOasis from '../components/HeaderOasis.jsx';

const DashboardPage = () => {
    //Define estados que armazenam dados do banco de dados e também são usados para navegação entre telas
    const [data, setData] = useState({});
    const [userId, setUserId] = useState("");
    const [currentProperty, setCurrentProperty] = useState("");
    const [productionUnits, setProductionUnits] = useState([]);

    const navigate = useNavigate();

    //Função de navegação ao clicar botão
    const handleClick = (id) => {
        switch (currentProperty) {
            case "ovonovo":
                navigate(`/dashboard/aviario/${id}`);
                break;
            case "oasis":
                navigate(`/dashboard/tanque/${id}`);
                break;
            default:
                console.warn(`Unknown property: ${currentProperty}`);
                navigate(`/dashboard/defaultPath/${id}`);
                break;
        }
    };

    //Recebe estrutura de dados inteira do firebase e uid do usuário autenticado
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await firebaseService.getDatabaseInfo();
                setData(data);

                const uid = getAuth().currentUser.uid;
                setUserId(uid);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Determinar a propriedade atual e configurar as unidades produtivas
    useEffect(() => {
        if (Object.keys(data).length > 0 && userId) {
            // Determina a propriedade com base no ID do usuário
            let idPropriedade_user = data.usuarios[userId].propriedadeId;
            setCurrentProperty(data.propriedades[idPropriedade_user].nome);
            // Configura as unidades produtivas para a propriedade atual
            setProductionUnits(Object.entries(data.propriedades[idPropriedade_user].unidadesProdutivas));
        }
    }, [data, userId]);


    const getImageSrc = (cultivo) => {
        switch (cultivo) {
            case 'fish':
                return iconePeixe;
            case 'shrimp':
                return iconeCamarao;
            default:
                return chickenicon;
        }
    };

    // Função para renderizar um cartão de unidade produtiva baseado na propriedade
    const renderUnitCard = ([id, unit]) => (
        <div className="card text-bg-secondary p-3" key={id} style={{ width: "17rem" }}>
            <img className="card-img-top imagem" src={getImageSrc(unit.cultivo)} alt='imagem' />
            <div className="card-body">
                <h5 className="card-title">{unit.nome}</h5>
                <p className="card-text">Clique para alterar os parâmetros</p>
                <button onClick={() => handleClick(id)} className="btn btn-primary">Configurar</button>
            </div>
        </div>

    );


    return (
            <div className='envolucro' style={{ width: '100%'}}>
                {currentProperty === 'ovonovo' ? <HeaderOvo/> : <HeaderOasis/>}
                <div className="unidades-produtivas-container">
                    {productionUnits.map(renderUnitCard)}
                </div>
            </div>
    );
};

export default DashboardPage;
